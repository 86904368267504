.f-faMpSeller {
  &--otherSeller {
    .f-faMpSeller__label {
      font-weight: normal;
      column-gap: 5px;
      flex-wrap: wrap;
      align-items: center;
    }
  }
  &__status {
    display: flex;
    margin-bottom: 10px;

    &Label {
      display: inline-block;
      color: @color-gray-darker;
      font-weight: normal;
      @media (max-width: @screen-sm-min) {
        &::before {
          background: @color-gray-darker;
          border-radius: 50%;
          content: '';
          display: inline-block;
          height: 6px;
          margin: 0 5px 1px 0;
          width: 6px;
        }
      }
    }
  }

  &--details &__status,
  &--details &__label {
    margin-bottom: 0;
  }

  &__label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    line-height: 2;
    margin-bottom: 12px;

    .f-faMpSeller__rating {
      margin-right: 0;
    }

    .f-icon--topSeller {
      margin-left: 2px;
      flex: 0 0 16px;
      color: @color-orange;
      font-size: 16px;
    }

    .f-icon--topSellerMedium {
      font-size: 20px;
      flex: 0 0 20px;

      & when (@ismobile) {
        font-size: 16px;
        flex: 0 0 16px;
      }
    }
  }

  &__soldBy {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 8px;
    column-gap: 5px;
    line-height: 22px;
  }

  &__text {
    flex: 1 0 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__sufix {
    display: flex;
    align-items: center;
    height: 22px;
    font-weight: 400;

    &--coma {
      margin-left: -5px;
    }

    &Icon--fulfilled {
      font-size: 38px;
      margin: -2px 0 0 6px;
    }
  }

  &__rating {
    display: inline-block;
    margin-right: 10px;

    &--other {
      margin-bottom: 8px;
    }
  }

  @famp-name-gap: 5px;
  &__name-content {
    display: flex;
    flex-wrap: nowrap;
    gap: @famp-name-gap;
    align-items: center;
    .tooltipFnacPlus-trigger {
      top: 0;
    }
  }

  &__name {
    font-weight: bold;
    text-decoration: none;
    color: @color-marketplace;

    @media (max-width: @screen-xs-max) {
      flex: 0 1 auto;
    }
  }

  &__sellerIcon {
    vertical-align: middle;
    height: 16px;
    &--fulfilled {
      font-size: 32px;
      color: @color-black;
      margin: 0 0 0 3px;
    }
  }

  &__salesCount {
    color: @color-gray;
  }

  &__pricePlusStatus {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 8px;
  }

  &__price {
    font-size: 1.8rem;
    font-weight: bold;
    color: @color-marketplace;

    &--fnac {
      font-size: 1.8rem;
      font-weight: bold;
      color: @color-red;
    }

    &--striked {
      color: @color-gray;
      font-size: 1.2rem;
      text-decoration: line-through;
      margin-left: 5px;
    }
  }

  &__flagIcon {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &__commentary {
    margin: 10px 0;
    padding-top: 15px;
    border-top: 1px solid @color-silver-lighter;
  }

  &__commentaryContent {
    color: @color-gray-darker;
  }

  &__delivery {
    @media (max-width: @screen-xs-max) {
      color: @color-lime-light;
    }
  }

  &__newLabel {
    display: inline-block;
    padding: 0 7px;
    font-size: 13px;
    color: @color-white;
    background-color: @color-marketplace;
    border-radius: 10px;
    white-space: nowrap;
  }

  &--priceBox {
    padding: 0;

    .f-productSection.f-productShopBox & {
      .f-faMpSeller__text {
        flex: none;
        justify-content: flex-start;
        flex-basis: 100%;
      }
    }
  }

  &__content {
    display: block;
    text-align: left;
    padding: 16px;
    border: 1px solid @color-silver-darker;
    border-radius: 5px;
    width: 100%;
    line-height: 1.5;
    font-family: inherit;
    font-size: 13px;
    background-color: @color-white;

    .f-faMpSeller__label {
      flex-direction: row;
      line-height: 1.2;
    }

    &:not(:first-child) {
      margin-top: 10px;
    }

    &:hover {
      background: @color-grey-light;
      cursor: pointer;
    }

    @media (max-width: @screen-xs-max) {
      position: relative;
    }

    .f-faMpSeller__status {
      margin-bottom: 0;
    }

    .f-rating {
      .f-rating-small();
    }
  }

  & .f-ratingContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    font-size: 13px;

    &-countryLabel {
      display: flex;
      font-weight: 400;
      line-height: 1;
      align-items: center;
      gap: 5px;
    }

    & .f-rating {
      font-size: 13px;
    }
  }
  &__shippingCountry {
    font-size: 13px;
    margin-top: 5px;
    & when (@ismobile) {
      margin-bottom: 15px;
    }
  }
}
