.f-info,
.LayerBasket__item-info {
  font-size: 11px;
  font-weight: normal;
  height: 14px;
  line-height: 1;
  margin: 2px 0;
  color: @color-gray-light;
  display: flex;
  align-items: center;
  width: 100%;

  &-tool {
    position: relative;
    margin-left: 4px;
    font-size: 12px;

    &:hover {
      cursor: pointer;
    }

    &:hover &tip {
      opacity: 1;
      visibility: visible;
    }

    &tip {
      line-height: 1;
      position: absolute;
      text-align: left;
      bottom: 25px;
      left: 0;
      background: @color-white;
      min-width: 200px;
      white-space: normal;
      padding: 8px;
      border-radius: 4px;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
      transform: translate(-47%);
      opacity: 0;
      transition: opacity 0.25s;
      visibility: hidden;
      z-index: 10;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        background: @color-white;
        width: 12px;
        height: 12px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-width: 0 1px 1px 0;
        transform: translate(-50%, 60%) rotate(45deg);
      }
    }
  }
}

.f-info {
  @media (min-width: 1200px) {
    //search list
    .Article-item & {
      justify-content: flex-end;

      &-tooltip {
        left: -95px;

        &::after {
          left: 95%;
        }
      }
    }
  }
  & when (@ismobile) {
    &-tool:focus &-tooltip {
      opacity: 1;
      visibility: visible;
    }
    .mosaic &-tooltip {
      min-width: 160px;
      left: -5px;
      &::after {
        left: 53%;
      }
    }
  }
}
