@import (reference) '../../../styles/_library.less';

.f-kamino-squad {
  position: relative;
  display: flex;
  background-color: @color-silver-darker;
  margin: 20px;
  flex-flow: column;
  box-shadow: 0 0 12px rgb(0 0 0 / 5%), 0 5px 8px 0 rgb(0 0 0 / 5%), 0 2px 4px 0 rgb(0 0 0 / 5%);
  border-radius: @base-radius @base-radius 0 0;
  .categoryMosaicCarousel & {
    background-color: @color-white;
    margin: 8px;
    height: 260px;
    width: 300px;
  }

  .f-kamino-advertisement {
    width: 100%;
    height: auto;
    border-radius: @base-radius @base-radius 0 0;

    &-wrapper {
      display: flex;
    }
  }

  .f-kamino-video {
    border-radius: @base-radius @base-radius 0 0;
  }

  .f-kamino {
    &-visual {
      width: 100px;
      align-self: center;
      margin-right: 8px;
    }
    &-sponsored {
      display: flex;
      justify-content: flex-end;
      color: @color-gray;
      align-items: center;
      position: absolute;
      bottom: -25px;
      border: 0;
      font-size: 16px;
      right: 0;
      background: transparent;
      .categoryMosaicCarousel & {
        font-size: 11px;
      }
      &--icon {
        font-size: 18px;
        margin-left: 5px;
        .categoryMosaicCarousel & {
          font-size: 15px;
        }
      }
    }
    &-info {
      background-color: @color-white;
      padding: 10px;
      display: flex;
      border-radius: 0 0 @base-radius @base-radius;
      flex-grow: 1;
      .categoryMosaicCarousel & {
        padding: 8px;
      }

      &--link {
        display: contents;
      }
    }
    &-buybox {
      display: flex;
      width: 70%;
      flex-grow: 1;
      column-gap: 10px;
      justify-content: flex-end;
      align-items: center;
      flex-direction: column;

      &-bottom {
        display: flex;
        align-self: end;
        gap: 0.5em;
        align-items: baseline;
        white-space: nowrap;
      }
      .price {
        font-size: 18px;
        color: @color-error;
        & when (@ismobile) {
          font-size: 23px;
        }
      }
      .oldPrice {
        font-size: 13px;
        color: @color-gray;
        & when (@ismobile) {
          font-size: 16px;
        }
      }
      &-promo {
        display: none;
      }
      &-promo {
        color: @color-error;
      }
    }
    &-title {
      color: @color-dark;
      flex-grow: 1;
      width: 100%;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  button {
    padding: 8px 10px;
    font-size: 1.6em;
    color: @color-black;
    & when (@ismobile) {
      font-size: 1.4em;
    }
  }
}

.categoryMosaic-wrapper {
  .f-kamino-squad {
    margin: 0;
  }
}

.row.categoryMosaic-wrapper {
  .categoryMosaic-advertising {
    position: relative;
    width: 46.5%;
    order: 1;

    @media (min-width: @screen-md-min) {
      width: 22.5%;
      order: 4;
    }

    @media (min-width: 1700px) {
      width: 15.5%;
      order: 6;
    }
    .categoryMosaicCarousel & {
      width: unset;
    }
  }

  .f-kamino-squad,
  .kmino-squad-home {
    // overflow: hidden;
    box-shadow: 0 0 12px rgb(0 0 0 / 5%), 0 5px 8px 0 rgb(0 0 0 / 5%), 0 2px 4px 0 rgb(0 0 0 / 5%);
    float: left;
    text-align: center;
    border: none;
    border-radius: 5px;
    margin: 8px;
    width: 46.5%;
    order: 1;
    position: relative;

    @media (min-width: @screen-md-min) {
      width: 22.5%;
      order: 4;
    }

    @media (min-width: 1700px) {
      width: 15.5%;
      order: 6;
    }

    .f-kamino {
      &-visual {
        width: 20%;
        height: auto;
        margin-right: 8px;
      }
      &-title {
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: wrap;
      }
      &-sponsored {
        border: 0;
        font-size: 12px;
        bottom: -25px;
      }
      &-buybox {
        &-promo {
          display: none;
        }
      }
    }
    .categoryMosaicCarousel & {
      width: 300px;
    }
  }
}

.js-lazy-html-cacheable {
  display: contents;
}

.mfp-container .mfp-content .f-kamino-sponsored-popin {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  border-radius: 3px;
  border: 1px solid @color-silver-darker;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 0;
}

.f-kamino-sponsored-popin {
  display: none;
  padding: 0;

  h1 {
    width: 100%;
    padding: 15px;
    border-bottom: 1px solid @color-silver-darker;
    color: @color-orange;
    font-size: 20px;
    font-weight: 600;
  }

  &-content {
    padding: 0 20px 15px;
    font-size: 14px;

    h2 {
      font-size: 16px;
      line-height: 18px;
    }
    h3 {
      font-size: 14px;
      line-height: 16px;
    }

    h2,
    h3 {
      font-weight: 700;
      margin-top: 17px;
      margin-bottom: 8.5px;
    }

    ul {
      list-style-type: disc;
      padding: 0 0 0 20px;
      margin: 5px 0;
    }

    a {
      text-decoration: underline;
      color: @color-dark-lighter;
    }
  }
}
