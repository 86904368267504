@import (reference) '../../../styles/_library.less';

.f-kamino-sword {
  position: relative;
  width: 100%;
  background: #fff;
  padding: 30px 30px 10px 30px;

  &__wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
  }

  &__media {
    position: relative;
    width: 240px;
    max-width: 240px;
    height: auto;
    display: flex;
    flex-basis: content;
    flex-shrink: 0;
    /* Image fomat */
    a {
      display: flex;
      img {
        width: 100%;
        height: auto;
        max-width: 100%;
      }
    }
    /* Video format */
    video {
      width: 240px;
      height: auto;
      max-width: 240px;
    }
  }

  /* Title */
  .f-productLayer__title {
    padding: 0 0 16px 0;
  }

  /* MiniFa */
  .miniFA.thumbnail {
    width: 240px;
    max-width: 240px;
    border: solid 1px @color-silver-lighter;
  }
  .miniFA__title {
    white-space: normal;
  }

  /* Carousel */
  .f-nCarousel {
    margin: 0;
    overflow-x: hidden;
    & when (@ismobile) {
      overflow-x: auto;
    }
    .nCarouselRoundedArrows();
    .nCarouselPositionArrows(10px);

    &__wrapper {
      width: 100%;
      height: 100%;
    }

    &--arrows {
      padding: 0;
      height: auto;
    }
  }

  /* Sponsored */
  .f-kamino {
    &-sponsored {
      background: white;
      display: flex;
      justify-content: flex-end;
      color: @color-gray;
      align-items: center;

      & when (@ismobile) {
        font-size: 12px;
      }

      &--icon {
        font-size: 18px;
        & when (@ismobile) {
          font-size: 12px;
        }
        margin-left: 5px;
      }
    }
  }
}
