.tooltipReview {
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 0;
  padding-top: 32px;
  width: 100%;
  display: none;
  line-height: 1.4;
  z-index: 1451;

  &.isActive {
    display: block;
  }

  &-container {
    position: absolute;
    width: 630px;
    background: @color-white;
    z-index: 100;
    min-height: 100px;
    border: 1px solid @color-silver-darker;
    white-space: normal;
    display: flex;
    box-shadow: 0 0 4px 1px @color-silver-darker;

    &::before {
      content: '';
      position: absolute;
      top: -10px;
      left: 40px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px;
      border-color: transparent transparent @color-silver-darker;
      line-height: 0;
    }

    &::after {
      content: '';
      position: absolute;
      top: -8px;
      left: 42px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 8px;
      border-color: transparent transparent @color-white;
      line-height: 0;
    }
  }

  &-comment {
    width: 328px;
    padding: 30px;
    float: left;
  }

  &-action {
    text-align: center;
    width: 300px;
    float: left;
    border-right: 1px solid @color-silver-darker;
  }

  &-actionTop {
    padding: 30px;
    line-height: 50px;
  }

  &-actionChart {
    padding: 20px 5px 5px;
    border-top: 1px solid @color-silver-darker;
  }

  &-showRate {
    margin-top: 20px;
  }

  &-actionTitle {
    color: @color-orange;
    font-weight: 300;
    font-size: 40px;

    span {
      font-weight: 100;
    }
  }

  &-action--bold {
    font-weight: bold;
    font-size: 1.6em;
  }

  // new style

  &-title {
    text-align: left;
    margin-top: 0;
    margin-bottom: 30px;
  }

  // Best review style

  &-reviewLine {
    display: block;
    margin-bottom: 5px;
    max-height: 110px;
    font-size: 13px;
    font-weight: 300;
    -webkit-line-clamp: 6;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-reviewTitle {
    font-weight: bold;
    display: inline-block;
    font-size: 15px;
  }

  &-reviewName {
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 0;
    margin-top: 15px;
  }

  &-reviewCity {
    font-size: 13px;
    font-weight: normal;
    color: @color-gray;
  }

  &-reviewOrigin {
    text-transform: uppercase;
    color: @color-dark;
    font-size: 11px;
    letter-spacing: 2px;
    font-weight: bold;

    &--verified {
      color: @color-lime-light;
      font-weight: normal;
    }
  }

  &-reviewTrustStatus {
    color: @color-gray;
    margin-bottom: 4px;
  }

  &-reviewRate {
    margin: 5px 0 15px;
  }
}
