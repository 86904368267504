// DISPO
//
// Disponibility Classes, available for all product disponibility
//
.Dispo,
.NotDispo,
.Nodispo,
.Sending,
.Deleted {
  margin: 0 0 0 18px;
  position: relative;
  display: block;

  .f-icon {
    margin-right: 5px;
    margin-left: 2px;
    font-size: 14px;
    position: absolute;
    right: 100%;
    top: 3px;
  }

  a,
  p,
  .openPopinShop {
    .typoFnacLight();
    cursor: pointer;
    display: block;
    margin: 0 0 0 unit((27px / 11px), em);
    color: @color-gray;
    text-decoration: none;
    font-size: 13px;
  }

  .openPopinShop {
    margin: 0;
    border: 0;
    background: none;
    padding: 0;

    &:hover {
      text-decoration: underline;
    }
  }
}

.Dispo-date {
  margin-left: 5px;
  display: inline-block;
}

.Dispo-txt {
  display: inline-block;
  vertical-align: top;
  width: auto;
  white-space: normal;
  left: 25px;
  font-size: 15px;
}

.Dispo-txt--MP {
  font-weight: bold;
}

.Dispo-mpSeller {
  color: @color-dark-lighter;
  font-size: 15px;
}

.Dispo {
  color: @color-forestGreen;
}

.Nodispo {
  color: @color-red;
  padding-bottom: 10px;
}

.Sending {
  color: @color-orange;
}

.Deleted {
  color: @color-dark;
}

.NotDispo {
  display: inline-block;
  color: @color-gray;
  margin-bottom: 5px;

  &:hover {
    text-decoration: none;
  }
}

.stressMarket {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  margin-left: 24px;
  + .Dispo-txt {
    text-transform: lowercase;
    margin-left: 0 !important;
  }
}

.Delivery {
  font-size: unit((11px / 13px), em);
  margin: 0 0 0 unit((27px / 11px), em);
  color: @color-gray;
}

.Delivery-txt {
  font-size: 13px;
  color: @color-gray;
}

.Delivery-price {
  color: @color-red;
}
