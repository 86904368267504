@import (reference) '../../../../../styles/_library.less';

// https://getbootstrap.com/docs/5.0/getting-started/accessibility/#visually-hidden-content
// a11y mixins
.sr-only() {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.sr-only-focusable() {
  &:not(:focus):not(:focus-within) {
    .sr-only();
  }
}

.sr-only,
.sr-only-focusable:not(:focus):not(:focus-within) {
  .sr-only();
}

// a11y styles
@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    animation-delay: 0s !important;
    animation-duration: 0.001ms !important;
    animation-iteration-count: 1 !important;
    transition-delay: 0s !important;
    transition-duration: 0.001ms !important;
    scroll-behavior: auto !important;
  }
}
