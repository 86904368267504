@import (reference) '../../../styles/_library.less';

.f-kamino-impulse {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9997;
  border-top: 1px solid #d8d8d8;
  cursor: pointer;

  .f-kamino-wrapper {
    position: relative;
    display: grid;
    width: 100%;
    height: 120px;
    background: #fff;
    grid-template-columns: 1fr 320px;
  }

  &[data-format='impulse-display'] .f-kamino-wrapper {
    grid-template-columns: 1fr;
  }

  .f-kamino-wrapper {
    position: relative;
    display: grid;
    width: 100%;
    height: 120px;
    background: #fff;
    grid-template-columns: 1fr 320px;
  }

  .f-kamino-image {
    position: relative;
    overflow: hidden;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .f-kamino-videoWrapper {
    display: flex;
  }

  .f-kamino-video {
    height: 120px;
    margin: auto;
  }

  .js-kamino-expand {
    position: absolute;
    top: -30px;
    right: 0px;
    width: 30px;
    height: 30px;
    padding: 0;
    background: rgba(50, 50, 50, 0.8);
    border: none;
    color: white;
  }
}

.f-kamino-impulse {
  .f-kamino-products {
    // width: 30%;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .f-nCarousel__itemInner {
    height: 120px;
  }

  .f-kamino-visual {
    width: 30%;
    position: relative;
    margin: 0 auto;

    img {
      width: 100%;
      height: auto;
    }
  }
  .js-ProductBuy,
  .js-kamino-link {
    display: contents;
  }

  .f-kamino-product {
    display: flex;
    min-width: 100%;
    overflow: hidden;
  }

  .f-kamino-title {
    line-height: 1.2em;
    font-size: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .f-kamino-buybox {
    width: 70%;
  }

  .f-kamino-buybox-price {
    display: flex;
    justify-content: space-between;
    align-items: end;

    .price {
      font-weight: 700;
      font-size: 19px;
      line-height: 19px;
      color: @color-red;
    }
  }
}

//Impulse Popin
.mfp-container .mfp-content .f-kamino-impulse-popin {
  display: flex;
  width: 80%;
  padding: 0;
  overflow: hidden;

  .f-kamino-video {
    width: 70%;
  }

  .f-kamino-products {
    width: 30%;
    margin: 0;
    padding: 0;
    list-style: none;
    max-height: 100%;
    position: absolute;
    left: 70%;
    transition: transform 0.5s ease-in-out;
  }

  .f-kamino-visual {
    min-width: 30%;
    position: relative;
    margin: 0 auto;

    img {
      width: auto;
      height: auto;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%;
      max-height: 100%;
    }
  }
  .js-ProductBuy {
    display: contents;
  }

  .f-kamino-product {
    display: flex;
    padding: 1em 2.5em 1em 1em;
  }

  .f-kamino-title {
    line-height: 1.2em;
    font-size: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .f-kamino-buybox {
    width: 70%;
  }

  .f-kamino-buybox-price {
    display: flex;
    justify-content: space-between;
    align-items: end;

    .price {
      font-weight: 700;
      font-size: 19px;
      line-height: 19px;
      color: @color-red;
    }
  }

  @media (max-width: 1025px) {
    flex-direction: column;

    .f-kamino-products {
      position: relative;
      width: 100%;
      left: 0;
      display: flex;
    }

    .f-kamino-products::-webkit-scrollbar {
      display: none;
    }

    .f-kamino-product {
      width: 100%;
      flex-shrink: 0;
    }

    .f-kamino-video {
      width: 100%;
    }
  }
}

.f-kamino-impulse-popin {
  display: none;
}

.stop-scrolling {
  height: 100% !important;
  overflow: hidden !important;
}
